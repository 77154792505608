.mainPrivacy {
    padding: 6vw 0;
    width: 83%;
    margin: auto;

    .entry-content {
        .privacyHeading {
            margin-bottom: 0.5rem;
            padding-top: 0;
            font-size: 1.5rem;
            color: #161616;
            font-family: "Josefin Sans", sans-serif;
            font-size: 45px;
            font-style: normal;
            font-weight: 600;
            line-height: 66.507px;
            text-transform: capitalize;
            margin: 0px;
            color: #1D2D5A;
            font-family: "Montserrat", sans-serif;
        }

        h1 {
            margin-bottom: 0.5rem;
            padding-top: 0;
            font-size: 1.5rem;
            color: #161616;
            font-family: "Josefin Sans", sans-serif;
            // font-size: 45px;
            font-style: normal;
            font-weight: 600;
            line-height: 66.507px;
            text-transform: capitalize;
            margin: 0px;
            padding-top: 5vh;
        }

        p,
        ul,
        li {
            margin-bottom: 0.5rem;
            font-family: "GothamLight";
            font-weight: 800;
            line-height: 1.2;
            color: black;
            padding-top: 0;
            font-size: 1rem;
            color: #7a7a7a;
            font-family: "GothamLight";
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0;
            line-height: 24px;
            margin: 0px;
            font-family: "Montserrat", sans-serif;
    margin: 1vw;
            // list-style: none;
        }
    }
}


@media only screen and (max-width: 600px) {
    .mainPrivacy {
        padding: 20vw 5vw;

        .entry-content {
            .privacyHeading {
                font-size: 10vw;
            }

            p {
                font-size: 14px;
                padding-top: 1vh;
            }

            h1 {
                font-size: 5vw;
                line-height: 30px;
            }

            ul li {
                // list-style-type: circle;
                padding-top: 10px;
                font-size: 14px;
                // list-style-image: url("../../src/assets/navBar/blackCircle.svg");

                // font-size: 14px;
                // margin-left: 10px;
                // list-style-type: decimal;
            }
        }
    }
}