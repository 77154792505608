.page2 {
  .videoSection {
    display: flex;
    flex-direction: column;
    gap: 2vh
  }

  .videoContainer {
    display: flex;
    align-items: center;
    justify-content: center;

    .video {
      border-radius: 20px;
      width: 85%;
      height: 36vh;
      border: 2px black solid;

      @media (min-width: 200px) and (max-width: 400px) {
        height: 20vh;
      }

      @media (min-width: 400px) and (max-width: 600px) {
        height: 22vh;
      }

      @media (min-width: 600px) and (max-width: 800px) {
        height: 28vh;
      }
    }

  }

  .Heading {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    .rightText {
      color: #303030;
      font-family: 'Poppins', sans-serif;
      font-size: 24px;
      font-weight: 700;
      line-height: 150%;
      text-transform: uppercase;
      display: flex;
      flex-direction: column;
      justify-content: center;

      span {

        text {
          color: #FB4500;
          font-family: 'Poppins', sans-serif;
          font-size: 24px;
          font-weight: 700;
          line-height: 150%;
          text-transform: uppercase;
        }

      }
    }
  }

  .subHeading {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;



    span {
      width: 85%;
      color: #303030;
      font-family: 'Poppins', sans-serif;
      font-size: 1.8vh;
      font-weight: 400;
      line-height: 3vh;


    }
  }
}