// font-family: 'Montserrat', sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.page9 {
  padding-top: 2vh;
  padding-bottom: 8vh;
  background: #f5f5f5;

  .Header {
    .Heading {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 4px;

      .rightText {
        // color: #303030;
        color: #1D2D5A;
        // font-family: "Poppins", sans-serif;
        font-family: 'Montserrat', sans-serif;
        font-size: 24px;
        font-weight: 700;
        line-height: 150%;
        text-transform: uppercase;
        display: flex;
        flex-direction: column;
        justify-content: center;

        span {
          text {
            // color: #fb4500;
            color: #1D2D5A;
            font-family: 'Montserrat', sans-serif;
            font-size: 24px;
            font-weight: 700;
            line-height: 150%;
            text-transform: uppercase;
          }
        }
      }
    }

    .subHeading {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      span {
        width: 85%;
        color: #303030;
        // font-family: "Poppins", sans-serif;
        font-family: 'Montserrat', sans-serif;
        font-size: 1.8vh;
        font-weight: 400;
        line-height: 3vh;
      }
    }
  }

  .accordion {
    color: #434343;
    text-align: right;
    // font-family: "Poppins", sans-serif;
    font-family: 'Montserrat', sans-serif;
    font-size: 12.668px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    /* 228.571% */
    letter-spacing: 0.724px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 24px 0px;
    cursor: pointer;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 11px;
    transition: 0.4s;
    background: rgba(232, 247, 255, 0.25);
    box-shadow: 0px 2px 11px 0px rgba(140, 140, 140, 0.14);
  }

  .acc {
    display: flex;
    justify-content: space-between;
    padding: 10px 12px;
    align-items: center;
    border-radius: 6px;
    height: 50px;
    width: 90%;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.28);
    transition: 0.4s;
  }

  .panel-hidden {
    padding: 0 18px;
    display: none;
    background-color: white;
    overflow: hidden;
    transition: 0.4s;
  }

  .panel {
    display: flex;
    flex-direction: column;
    background-color: white;
    overflow: hidden;
    width: 85%;
    padding: 14px;
        // color: rgba(48, 48, 48, 0.81);
        color: #303030;
        // font-family: "Poppins", sans-serif;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 131.043%;
        transition: 0.4s;
  }

  .viewMore {
   
    // font-family: "Poppins", sans-serif;
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 131.043%;
  }

  .sign{
    border-radius: 4px;
    box-shadow: 0px 4px 10.2px 0px rgba(0, 0, 0, 0.26);
    display: flex;
    align-items: center;
    justify-content: center;

  }
}
