// font-family: 'Montserrat', sans-serif;
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.page1 {
  display: block;
  // width: 100%;

  .topSection {
    // width: 100%;
    // background: rgba(50, 95, 162, 0.51);

    .topVideo {
      height: 100vh !important;
      display: inlineBlock !important;
      width: 100% !important;
      object-fit: cover !important;
      z-index: 9;
    }

    .overly {
      width: 100%;
      height: 100vh;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 99;
      background: rgb(31 31 31 / 51%);
    }

    .video-content {
      bottom: 127px;
      left: 0;
      margin: auto;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 999;
      // background: rgba(50, 95, 162, 0.51);

      .upperDrop {
        width: 100%;
        padding: 25px 0;
        border-radius: 0px 0px 80px 80px;
        // background: #0a2259;
        text-align: center;

        img {
          height: 90px;
          margin: auto;
        }
      }

      .middleHead {
        position: relative;
        // top: 12vh;
        top: 7vh;

        .firstMiddleName {
          text-align: center;
          font-family: "Montserrat", sans-serif;
          font-size: 20px;
          font-style: normal;
          font-weight: 800;
          line-height: 125%;
          letter-spacing: 4.4px;
          text-transform: uppercase;
          background: linear-gradient(180deg, #ebebeb 0%, #e7e7e7 100%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        .thirdMiddleName {
          color: #fff;
          text-align: center;
          font-family: "Montserrat", sans-serif;
          font-size: 40px;
          font-style: normal;
          font-weight: 700;
          line-height: 125%;
          letter-spacing: 8.8px;
          text-transform: uppercase;
        }
      }

      .hrLineClass {
        width: 338px;
        height: 1px;
        background: rgba(255, 255, 255, 0.62);
        top: 12vh;
        position: relative;
        margin: auto;
        opacity: 0.6;
      }

      .belowPara {
        position: relative;
        top: 20vh;

        h1 {
          color: #e5e5e5;
          text-align: center;
          font-family: "Montserrat", sans-serif;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%;
          letter-spacing: 1.2px;
          width: 80%;
          margin: auto;
        }
      }
    }
  }

  // .bottomShow {
  //   display: flex;
  //   width: 95%;
  //   padding: 3vh 2vh;
  //   padding-right: 0px;
  //   justify-content: center;
  //   align-items: center;

  //   .innerBottomShow {
  //     width: 100%;
  //     display: grid;
  //     grid-template-columns: 14vh 10px 1fr;

  //     .img {
  //       width: 100%;

  //       img {
  //         width: 100%;
  //       }
  //     }

  //     .lineDrop {
  //       img {
  //         height: 14vh;
  //       }
  //     }

  //     .rightText {
  //       color: #303030;
  //       font-family: "Poppins", sans-serif;
  //       font-size: 20px;
  //       font-weight: 700;
  //       line-height: 150%;
  //       text-transform: uppercase;
  //       display: flex;
  //       flex-direction: column;
  //       justify-content: center;
  //       align-items: baseline;

  //       span {
  //         color: #fb4500;
  //         text-align: center;
  //         font-family: "Poppins", sans-serif;
  //         font-size: 20px;
  //         font-weight: 700;
  //         line-height: 150%;
  //         text-transform: uppercase;
  //         justify-content: center;
  //         display: flex;
  //         flex-direction: column;
  //         align-items: baseline;
  //       }
  //     }
  //   }
  // }
}