
// font-family: 'Montserrat', sans-serif;
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.page4 {

  @media (min-width:800px){
    display: none;
  }
  display: block;
  padding-top: 2vh;
  gap: 4vh;
  margin-bottom: 2vh;

  .Header {
    .Heading {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 4px;

      .rightText {
        color: #1d2d5a;
        font-family: "Montserrat", sans-serif;
        font-size: 24px;
        font-weight: 700;
        line-height: 150%;
        text-transform: uppercase;
        display: flex;
        flex-direction: column;
        justify-content: center;

        // span {
        //   text {
        //     color: #fb4500;
        //     font-family: "Montserrat", sans-serif;
        //     font-size: 24px;
        //     font-weight: 700;
        //     line-height: 150%;
        //     text-transform: uppercase;
        //   }
        // }
      }
    }

    .subHeading {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      span {
        width: 85%;
        color: #303030;
        font-family: "Montserrat", sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 3vh;
      }
    }
  }
}

.overlay-1 {
  position: absolute;
  bottom: 12px;
  left: 7px;
  width: 95%;
  height: 15%;
  background: linear-gradient(
    180deg,
    rgba(43, 43, 43, 0.26) 0%,
    rgba(43, 43, 43, 0.26) 100%
  );
  pointer-events: none;
  border-radius: 20px;
  display: flex;
  padding: 8px 0px;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  align-items: baseline;

  span {
    color: #fff;
    padding-left: 16px;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 21.6px */
    text-transform: uppercase;
  }

  text {
    color: #fff;
    padding-left: 16px;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 14.4px */
    text-transform: capitalize;
  }
}

.mobile-carousel-container {
  width: 100%;
  overflow: hidden;

  .carousel-track {
    overflow: scroll;
    padding-left: 2vh;
    scroll-snap-type: both mandatory;
    scroll-padding: 1rem;
    transition: transform 0.3s ease-in-out;

    .carousel-slide {
      box-sizing: border-box;
      text-align: center;

      width: 100%;
      flex-shrink: 0;
    }
  }

  .active {
    scroll-snap-type: unset;
  }

  .carousel-track::-webkit-scrollbar {
    display: none !important;
  }

  .carousel-track::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
}

.carousel-track::-webkit-scrollbar {
  display: none !important;
}

.posts {
  border-radius: 10px;
  border: 1px solid rgba(21, 47, 122, 0.45);
  background: #fff;
  box-shadow: 0px 4px 40px -13px rgba(159, 159, 159, 0.22);
  box-shadow: 0px 4px 40px -13px rgba(159, 159, 159, 0.22);
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 90vw;
  justify-content: space-between;
  min-height: 400px;
}

.details {
  padding: 0px 10px;
  margin-bottom: 18px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 8px;

  .div1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    // border-radius: 10px;
    // background: rgba(253, 212, 197, 0.25);
    border-radius: 10px;
    background: #e9e9e9;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);

    .icon {
      width: 50px;
      height: 50px;
      padding-top: 12px;
      padding-bottom: 4px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .content {
      margin: 8px;
      padding-bottom: 12px;
      margin-top: 0px;
      border-bottom: 1px black solid;

      span {
        color: #303030;
        font-family: "Montserrat", sans-serif;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%;
        /* 13px */
        text-transform: capitalize;
      }
    }

    .data {
      display: flex;
      padding-bottom: 8px;

      span {
        // color: #fb4500;
        color: #1d2d5a;
        font-family: "Montserrat", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%;
        /* 18.2px */
        text-transform: capitalize;
      }
    }
  }

  .div2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: #e9e9e9;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);

    .icon {
      width: 50px;
      height: 50px;
      padding-top: 12px;
      padding-bottom: 4px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .content {
      margin: 8px;
      margin-top: 0px;
      padding-bottom: 12px;
      border-bottom: 1px black solid;

      span {
        color: #303030;
        font-family: "Montserrat", sans-serif;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%;
        /* 13px */
        text-transform: capitalize;
      }
    }

    .data {
      display: flex;
      padding-bottom: 8px;

      span {
        color: #1d2d5a;
        font-family: "Montserrat", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%;
        /* 18.2px */
        text-transform: capitalize;
      }
    }
  }

  .div3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-radius: 10px;
    background: #e9e9e9;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);

    .icon {
      width: 50px;
      height: 50px;
      padding-top: 12px;
      padding-bottom: 4px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .content {
      margin: 8px;
      margin-top: 0px;
      padding-bottom: 12px;
      border-bottom: 1px black solid;

      span {
        color: #303030;
        font-family: "Montserrat", sans-serif;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%;
        /* 13px */
        text-transform: capitalize;
      }
    }

    .data {
      display: flex;
      padding-bottom: 8px;

      span {
        color: #1d2d5a;
        font-family: "Montserrat", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%;
        /* 18.2px */
        text-transform: capitalize;
      }
    }
  }
}

.avatar {
  width: 100%;
  height: 260px;
  border-radius: 20px;
  object-fit: cover;
}
