// font-family: 'Montserrat', sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.pagedesktop7 {
  @media (min-width:800px){
    display: block;
  }
  display: none;
  gap: 4vh;
  background: #FFF;

  .Header {
    padding-top: 20px;

    .Heading {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 4px;

      .rightText {
        // color: #303030;
        color: #1D2D5A;
        // font-family: "Poppins", sans-serif;
        font-family: 'Montserrat', sans-serif;
        font-size: 26px;
        font-weight: 700;
        line-height: 150%;
        text-transform: uppercase;
        display: flex;
        flex-direction: column;
        justify-content: center;

        // span {
        //   text {
        //     color: #fb4500;
        //     font-family: "Poppins", sans-serif;
        //     font-size: 24px;
        //     font-weight: 700;
        //     line-height: 150%;
        //     text-transform: uppercase;
        //   }
        // }
      }
    }

    .subHeading {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      span {
        width: 85%;
        color: #303030;
        // font-family: "Poppins", sans-serif;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
      }
    }
  }

  .ourExpertTrainerMain1 {
    .ourExpertTrainerMainContainer1{
      display: grid;
      // flex-direction: column;
      grid-template-columns: 1fr 1fr 1fr;
      // gap: 3vw;
      overflow: hidden;
      padding-top: 3vh;
      // padding-left: 4vw;
  
      .demoCheckColor {
        // width: 94%;
        flex-shrink: 0;
        // padding-left: 1vw;
        // padding-left: 3vw;
  
        .ourExpertTrainerBorderRadius {
          // border-radius: 10px;
          // border: 1px solid rgba(21, 47, 122, 0.45);
          // background: #fff;
          // box-shadow: 0px 4px 40px -13px rgba(159, 159, 159, 0.22);
          display: flex;
          padding: 15px;
          gap: 7vw;
          // flex-shrink: 0;
          align-items: center;
          justify-content: center;
  
          .adjustSlider {
            border-radius: 10px;
            border: 1px solid rgba(21, 47, 122, 0.45);
            background: #fff;
            box-shadow: 0px 4px 40px -13px rgba(159, 159, 159, 0.22);
            width: 95%;
            // height: 46vh;
  
            .firstSection {
              flex-shrink: 0;
              width: 100%;
  
              .firstSectionImage {
                position: relative;
                // padding: 10px;
                // margin: 0px 10px 8px 10px
                padding: 10px 10px 5px 10px;
  
                img {
                  width: 28vw;
                  height: 48vh;
                  object-fit: cover;
                }
  
                .overLayText {
                  position: absolute;
                  bottom: 15px;
                  padding: 10px 8px;
                  width: 87%;
                  height: 5vh;
                  border-radius: 0px 0px 10px 10px;
                  background: linear-gradient(
                    180deg,
                    rgba(50, 95, 162, 0.44) 0%,
                    rgba(43, 43, 43, 0.26) 100%
                  );
                  .nameClass {
                    color: #fff;
                    text-align: center;
                    font-family: "Montserrat", sans-serif;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 120%;
                    text-transform: uppercase;
                  }
  
                  .designationClass {
                    color: #fff;
                    font-family: "Montserrat", sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 120%;
                    text-transform: capitalize;
                    text-align: center;
                  }
                }
              }
  
              .belowSection {
                // background: white;
                .belowSectionBorder {
                  border-radius: 10px;
                  // background: #e9e9e9;
                  // box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
                  margin: 0px 10px 5px 10px;
  
                  display: grid;
                  grid-template-columns: 1fr 1fr 1fr;
                  align-items: center;
                  justify-content: center;
                  // padding: 10px;
                  gap: 6px;
  
                  .belowSectionDiv {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    background: #e9e9e9;
                    padding: 10px;
                    border-radius: 15px;
  
                    .belowSectionImage {
                      width: 40%;
  
                      img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                      }
                    }
  
                    .belowHead {
                      color: #1d2d5a;
                      font-family: "Montserrat", sans-serif;
                      font-size: 12px;
                      font-style: normal;
                      font-weight: 600;
                      line-height: 130%;
                      text-transform: capitalize;
                      margin: 0px;
                    }
  
                    .belowHr {
                      width: 58px;
                      height: 0 px;
                      background: #1d2d5a;
                    }
  
                    .belowYears {
                      color: #1d2d5a;
                      font-family: "Montserrat", sans-serif;
                      font-size: 15px;
                      font-style: normal;
                      font-weight: 600;
                      line-height: 130%;
                      text-transform: capitalize;
                      margin: 0px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  
    .progressBar {
      align-items: center;
      justify-content: center;
      display: flex;
      width: 100%;
      padding-top: 2vh;
      gap: 10vw;
  
      .dot {
        height: 10px;
        width: 10px;
        margin: 0 5px;
        background-color: #d9d9d9;
        border-radius: 50%;
        display: inline-block;
      }
  
      .active {
        background-color: #1d2d5a;
      }
    }
  }

}




