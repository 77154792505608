// font-family: 'Montserrat', sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.page7 {
  gap: 4vh;
  background: #FFF;

  .Header {
    padding-top: 20px;

    .Heading {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 4px;

      .rightText {
        // color: #303030;
        color: #1D2D5A;
        // font-family: "Poppins", sans-serif;
        font-family: 'Montserrat', sans-serif;
        font-size: 26px;
        font-weight: 700;
        line-height: 150%;
        text-transform: uppercase;
        display: flex;
        flex-direction: column;
        justify-content: center;

        // span {
        //   text {
        //     color: #fb4500;
        //     font-family: "Poppins", sans-serif;
        //     font-size: 24px;
        //     font-weight: 700;
        //     line-height: 150%;
        //     text-transform: uppercase;
        //   }
        // }
      }
    }

    .subHeading {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      span {
        width: 85%;
        color: #303030;
        // font-family: "Poppins", sans-serif;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
      }
    }
  }
}

.mobile-carousel-container {
  width: 100%;
  overflow: hidden;
  padding-top: 5vh;

  .carousel-track {
    overflow: scroll;
    padding-left: 2vh;
    scroll-snap-type: both mandatory;
    scroll-padding: 1rem;
    transition: transform 0.3s ease-in-out;

    .carousel-slide {
      box-sizing: border-box;
      text-align: center;
    }
  }

  .active {
    scroll-snap-type: unset;
  }

  .carousel-track::-webkit-scrollbar {

    display: none !important;

  }


  .carousel-track::-webkit-scrollbar {
    width: 0;
    /* Remove scrollbar space */
    background: transparent;
    /* Optional: just make scrollbar invisible */
  }
}

.carousel-track::-webkit-scrollbar {

  display: none !important;

}


.carousel-track::-webkit-scrollbar {
  width: 0;
  /* Remove scrollbar space */
  background: transparent;
  /* Optional: just make scrollbar invisible */
}

.post {
  border-radius: 6px;
  // border: 0.5px solid #fb4500;
  border: 1px solid #325FA2;
  background: #fff;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.28);
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 70vw;
  justify-content: space-between;
  min-height: 300px;
  overflow: auto;

  @media (min-width:1064px) {
    width: 35vw
  }
}


.detailed {
  display: grid;
  grid-template-rows: 2fr 1fr;
  height: 300px;


  .content {
    display: flex;
    padding: 10px;
    text-align: flex-start;
    color: #7e7e7e;
    // font-family: "Poppins", sans-serif;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 121.043%;
    letter-spacing: -1px;

  }

  .name {

    display: flex;
    flex-direction: column;
    gap: 4px;
    text-align: justify;
    padding-left: 10px;
    justify-content: flex-end;

    span {
      // color: #000;
      color: #1D2D5A;
      // font-family: "Poppins", sans-serif;
      font-family: 'Montserrat', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 131.043%;
      padding-bottom: 12px;
    }

    text {

      // color: #FA4300;
      color: #4B7745;
      // font-family: "Poppins", sans-serif;
      font-family: 'Montserrat', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 131.043%;
 

    }

  }
}

.threedotss {
  padding: 4vw 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .carousel-dots {
    display: flex;
    gap: 4px;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: hidden;
  }

  .press-carousel-item {
    flex: 0 0 auto;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: flex;
    background-color: #fff;
    border: 0.5px #000 solid;
  }

  .press-carousel-item.active {
    background-color: #FB4500;
    ;
    color: #fff;
  }
}




.scroll-container {
  width: 80vw;
  overflow-x: auto;
  white-space: nowrap;
  background-color: rgba(211, 211, 211, 0.866);
  padding: 1px;
  position: relative;
  left: 12%;
  margin-top: 30px;
  margin-bottom: 40px;
}

.scroll-progress {
  position: absolute;
  top: 0;
  left: 0;
  height: 4px;
  background-color: #1D2D5A;
  width: 0;
  transition: width 0.3s ease;
}