@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");


.footerMain {
    background: #1D2D5A    ;

    .leftArea {
        display: flex;
        flex-direction: column;
        gap: 5svh;

     

        .pinkBox {
          position: relative;
          top: 33%;
          left: 5%;
          padding: 5% 0;

            &::before {
                content: "";
                background: #134B6E;
                border-radius: 10px;
                position: absolute;
                top: 0;
                left: 0;
                width: 50%;
                height: 100%;
                z-index: 0;

                @media (max-width:700px) {
                  width: 95%;
                }
            }

            .massagePinkBox {
                position: relative;
                text-transform: uppercase;
                margin-left: 5%;
                font-size: small;
                color: rgba(255, 255, 255, 0.5);
                z-index: 10;


                b {
                    text-transform: lowercase;
                    color: #FFFFFF;
                    font-size: large;
                    line-height: 40px;
                }

                a {
                    color: #fff;
                    text-decoration: none;
                    cursor: pointer;
                }
            }
        }
    }
 
    .footerMainContainer {
      padding: 0vh 5vw 16vh 5vw;
      display: grid;
      justify-content: center;
  
        grid-template-columns: 1fr;
      
  
      .leftSideFooter {
        display: flex;
        flex-direction: column;
        padding-bottom: 32px;
        // gap: 30px;
        z-index: 9999;
      
          align-items: center;
        //   padding: 36px 0px;
      border-bottom: 2px dotted white;
    //   border-top:1px solid white ;
    .footerLogo {
        width: 25%;

        img {
            width: 100%;
        }
    }

    .allSocialLink {
        display: flex;
        gap:8px;
        flex-direction: row;

        .socialInline {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 47.264px;
                border: 1.63px solid rgba(255, 255, 255, 0.47);

            .socialAnchor {
                display: grid;
      
                justify-content: center;
                border: none;
           

                img {
                   
                  color: transparent;
                  width: 22px;
                  height: 22px;
                  margin: auto;
                  padding: 16px;
                  object-fit: contain;
              
              
                }
            }

           
        }



    }
        
  
        .footerText {
          @media (min-width: 200px) and (max-width: 1064px) {
            text-align: center;
          }
    
          text {
            color: #fff;
            text-align: center;
            font-family: "Montserrat", sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 130.5%; /* 20.88px */
          }
        }
  
        .footerLogo {
          width: 16vw;
        //   background-color: white;
          padding: 8px;
          display: flex;
          align-items: center;
  
          @media (min-width: 200px) and (max-width: 1064px) {
            width: 50vw;
            // background-color: white;
            padding: 20px;
            display: flex;
            align-items: center;
            border-radius: 20px;
          }
  
          img {
            width: 100%;
            height: 80%;
          }
        }
  
        .locationEmailDiv {
          z-index: 99;
  
          .locationEmailImg {
            .locationImageDiv {
              display: flex;
              flex-direction: row;
              gap: 15px;
              align-items: center;
  
              img {
                width: 4%;
                height: auto;
              }
  
              p,
              a {
                color: #fff;
                font-family: "Montserrat", sans-serif;
                font-size: 15px;
                font-style: normal;
                font-weight: 300;
                text-decoration: none;
                opacity: 0.9;
              }
            }
          }
        }
      }
  
  
      .rightMainSection {
        .links {
          h3 {
            color: #fff;
            font-family: "Montserrat", sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            text-transform: capitalize;
          }
  
          ul {
            list-style: none;
  
            li {
              color: #dfdbdb;
              font-family: "Montserrat", sans-serif;
              font-size: 15px;
              font-style: normal;
              font-weight: 500;
              text-transform: capitalize;
            }
          }
        }
      }
    }
  
    .allRightSection {
      display: flex;
      margin: 8vh 5vw 0vh 5vw;
      border-top: 2px solid #fcfeff;
      text-align: center;
      justify-content: space-between;
  
  
      @media (min-width: 200px) and (max-width: 1064px) {
        display: flex;
        margin: 0vh 5vw 0vh 5vw;
        border-top: 2px solid #fcfeff;
        text-align: center;
        justify-content: center;
      }
  
      h2 {
        color: #fcfeff;
        padding: 8px 0px;
        font-family: "Montserrat", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
  
      .socialMediaIcons {
        // width: 15px;
        // height: 20px;
        display: flex;
        flex-direction: row;
        gap: 30px;
        align-items: center;
        cursor: pointer;
  
        a {
          img {
            width: 100%;
          }
        }
      }
    }
    .content{
      display: flex;
      justify-content: center;
      align-items: center;
      width:100%;
      padding: 18px 0px;

      
      border-bottom: 2px dotted white;
      gap:14px;
      flex-direction: column;

      span{
        color: #FFF;

text-align: right;
font-family: Montserrat;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 153.023%; /* 30.605px */
letter-spacing: 0.4px;
      }

      text{
        color: var(--White, #FFF);
text-align: center;
font-family: Montserrat;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 23.722px;
      }
    }
  }
  
  .footermain1 {
    display: block;
  }
  
  .footermain2 {
    display: none;
  }
  
  @media (min-width: 200px) and (max-width: 1064px) {
    .footermain1 {
      display: none;
    }
  
    .footermain2 {
      display: block;
      height: max-content;
    }
  }
  