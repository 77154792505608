// font-family: 'Montserrat', sans-serif;
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.page3 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  // padding-top: 8vh;
  gap: 4vh;
  margin-bottom: 2vh;

  .buttonDownload {
    width: 75%;

    .button {
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      background: linear-gradient(180deg, #fb4500 0%, #ff8d61 100%);
      border-radius: 60px;
      height: 6vh;

      span {
        color: white;
        // font-family: "Poppins", sans-serif;
        font-family: "Montserrat", sans-serif;
        font-size: 1.8vh;
        font-weight: 600;
        line-height: 3vh;
      }
    }
  }

  .program {
    // width: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2vh;
    background: white;
    border-radius: 12px;
    box-shadow: 4px 4px 10px 4px rgba(185, 182, 182, 0.25);
    padding-bottom: 3vh;

    .Heading {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 8px 0px;
      padding-top: 3vh;

      .rightText {
        color: #1d2d5a;
        font-family: "Montserrat", sans-serif;
        font-size: 24px;
        font-weight: 700;
        line-height: 150%;
        text-transform: uppercase;
        display: flex;
        flex-direction: column;
        justify-content: center;

        span {
          text {
            color: #fb4500;
            font-family: "Poppins", sans-serif;
            font-size: 24px;
            font-weight: 700;
            line-height: 150%;
            text-transform: uppercase;
          }
        }
      }
    }

    .videoBelowPara {
      p {
        color: #325fa2;
        font-family: "Montserrat", sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }

    .programList {
      display: flex;
      flex-direction: column;
      gap: 5vh;
      padding-bottom: 2vh;

      @media (min-width:800px){
        display: grid;
        flex-direction: column;
        gap: 5vh;
        padding: 0px 12px;
        padding-bottom: 2vh;
        grid-template-columns: 1fr 1fr 1fr;
      }

      .listContainer {
        display: flex;
        gap:"12px";
        @media (min-width:800px){
          // flex-direction: column;
          // justify-content: center;
          // align-items: center;
        }

        .listImg {
          display: flex;
          width: 25%;
          justify-content: center;
          align-items: center;
        }

        .listContent {
          display: flex;
          align-items: center;
          text-align: left;
          width: 75%;

          span {
            color: #303030;
            font-family: "Montserrat", sans-serif;
            font-size: 16px;
            font-weight: 600;
            line-height: 3vh;
            width: 80%;
          }
        }
      }
    }

    .downloadBrochure {
      display: flex;
      align-items: center;
      justify-content: center;

      .downloadBrochureButton {
        border-radius: 60px;
        background: #00468b;
        font-family: "Montserrat", sans-serif;
        color: #fff;
        font-size: 17px;
        padding: 15px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        margin: 4px 2px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 2.16px;
        text-transform: uppercase;
      }
    }
  }
}