.buttons-align{

    display: flex;
    justify-content: flex-end;
    background: transparent;
    position: fixed;
    bottom: 16px;
    width: 100%;



    .form{
 
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        border-radius: 50%;
        width:70px;
        height: 70px;
        margin-left: 12px;
        
        box-shadow: 4px 4px 10px 4px rgba(185, 182, 182, 0.25);


    }




    .bate{

        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        border-radius: 50%;
        width:70px;
        height: 70px;

        box-shadow: 4px 4px 10px 4px rgba(185, 182, 182, 0.25);
        margin-right: 12px;

    }
}

// ---------------------------------------
.popup-screen{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: grid;
    place-content: center;
    animation: _ngcontent-prf-c4_leadFormEnable 1s ease;
    z-index: 999999;
    backdrop-filter: blur(5px);

.popup-box{
    width: 30vw;
    // position: fixed;
    background: white;
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    color: #325fa2;
    z-index: 9999;
    padding: 2vw;
    top: 33vh;
    background: #fff;
    overflow-y: hidden;
    z-index: 100;
    border-radius: 20px;

    .ok-popup{
        border-radius: 60px;
        background: #00468b;
        font-family: "Montserrat", sans-serif;
        color: #fff;
        font-size: 18px;
        padding: 15px 32px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 2px;
        margin-bottom: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 2.16px;
        cursor: pointer;
    }
}
}