@mixin form-element {
  border: none;
  outline: none;
  color: rgba(0, 0, 0, 0.61);
  font-family: "Poppins", sans-serif;
  font-size: 0.79169rem;
  font-style: normal;
  font-weight: 500;
  // text-transform: capitalize;
}

.dialog-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dialog {
  // position: fixed;
  // top: 0;
  // left: 0;
  // width: 100%;
  // height: 100%;
  // background: rgba(0, 0, 0, 0.5);
  // display: flex;
  position: fixed;
  display: flex;
  justify-content: center;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 100px 0px;
  border-radius: 8px;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  z-index: 10000;
  border-radius: 10px;
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.61);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.61);
}

.dialog-content {
  display: flex;
  background-color: white;
  flex-direction: column;
  padding: 8vw 4vw;
  // padding: 6vw 4vw;
  justify-content: center;
  align-items: center;
  width: 80%;
  // height: 71vh;
  height: 65vh;
  border-radius: 20px;

  fieldset {
    border-radius: 40px;
    border: 1.5px solid rgba(48, 48, 48, 0.08);
    // border-radius: 0.62025rem;
    // border: 0.877px solid rgba(0, 0, 0, 0.25);
    background: #fff;
    // margin-bottom: 20px;
    position: relative;
    padding-left: 14px;
    margin: 10px 0px;
    width: 90%;

    legend {
      background-color: #fff;
      padding: 0 2px;
      position: absolute;
      top: -12px;
      left: 16px;
      color: rgba(45, 44, 44, 0.65);
      font-family: "Poppins", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      text-transform: capitalize;
    }

    input {
      @include form-element;
      width: 100%;
      // padding: 5px 0;
      padding: 5px 0px 0px 0px;
      color: rgba(0, 0, 0, 0.7);
      background-color: #fff;
    }

    select {
      @include form-element;
      text-align: left;
      width: 100%;
      padding: 10px 0;
      // cursor: pointer;
      // -webkit-appearance: none;
      // -webkit-back

      // border: none;
      // color: #2d3748;
      // cursor: pointer;
      // font-size: 0.79169rem;
      // font-weight: 500;
      // outline: none;
      // padding: 10px 0;
      // text-align: left;
      // width: 100%;
      background-color: #fff;
    }
  }

  .dropDo-content {
    position: absolute;
    background-color: #ffffff;
    min-width: 100%;
    left: 0;
    top: 50px;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
    margin: 0px;

    div {
      color: #838181;
      padding: 2px 16px;
      text-decoration: none;
      display: block;
      font-family: "Poppins", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 350;
      line-height: 30.921px;
      letter-spacing: 0.2px;
      border-radius: 10px;
    }
  }
}

.int1 {
  border-radius: 20px;
  border: 1.5px solid rgba(0, 0, 0, 0.05);
  padding: 6px 0px 6px 10px;
  background: #f8f5f5;
}

.int {
  border: none;
  background: transparent;
  @include form-element;
  width: 100%;
  padding: 4px 0;
}

.button1 {
  display: flex;
  padding: 8px 14px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: 1.5px solid rgba(0, 0, 0, 0.05);
  background: #5a5a5a;

  text {
    color: #fff;

    font-family: "Poppins", sans-serif;
    font-size: 12.5px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
  }
}

.button2 {
  display: flex;
  padding: 9px 18px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: 1.5px solid rgba(0, 0, 0, 0.05);
  background: #29b22e;
  width: 100%;

  span {
    color: #fff;
    display: inline-flex;
    font-family: "Poppins", sans-serif;
    font-size: 12.5px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    align-items: center;
  }
}

.otp {
  display: grid;
  grid-template-columns: 140px 1fr 1fr;
  gap: 12px;
  margin: 8px 0px;
}

.aggriment {
  display: inline-flex;
  gap: 8px;
  justify-content: center;
  align-items: flex-start;
  padding-top: 2vh;

  input {
    width: 28px;
    height: 28px;
  }

  p {
    color: rgba(0, 0, 0, 0.4);
    text-align: left;
    font-family: "Poppins", sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0px;
  }
}

.buttonDownload {
  width: 95%;
  padding-top: 4vh;

  .button {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    // background: linear-gradient(180deg, #FB4500 0%, #FF8D61 100%);
    border-radius: 60px;
    background: #00468b;
    height: 5vh;
    cursor: pointer;

    span {
      color: white;
      font-family: "Poppins", sans-serif;
      font-size: 1.8vh;
      font-weight: 400;
      line-height: 3vh;
    }
  }

  .buttonCircle {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    background: transparent;
    height: 5vh;

    span {
      color: white;
      font-family: "Poppins", sans-serif;
      font-size: 1.8vh;
      font-weight: 400;
      line-height: 3vh;
    }
  }
}

.ok {
  display: flex;
  width: 78.975px;
  padding: 6px 0px;
  justify-content: center;
  align-items: center;
  // border-radius: 5px;
  // border: 1px solid #a31717;
  border-radius: 5px;
  border: 1px solid #88b4ef;
  background: #00468b;
  position: relative;
  left: 34%;
  // background: #fb4500;
  color: white;
  font-family: "Poppins", sans-serif;
  font-size: 1.8vh;
  font-weight: 400;
  line-height: 3vh;
}

.button-dialog {
  border: 0;
  position: relative;
  top: -24px;
  background-color: transparent;
  left: 35vw;
  // display: flex;
  // align-items: baseline;
  // justify-content: center;
  // gap: 10vw;

  // .upperPopUpDiv {
  //   color: #303030;
  //   text-align: center;
  //   font-family: "Poppins", sans-serif;
  //   font-size: 18px;
  //   font-style: normal;
  //   font-weight: 500;
  //   line-height: 115%; /* 20.7px */
  //   text-transform: capitalize;
  // }
}

.UpperPopUpHead {
  color: #303030;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 115%;
  /* 20.7px */
  text-transform: capitalize;
  margin: 0px;
  padding-bottom: 3vh;
}