// font-family: 'Montserrat', sans-serif;
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.page6 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  background: #e9e9e9;

  .Heading {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    // padding-top: 3vh;

    .rightText {
      // color: #303030;
      color: #1d2d5a;
      // font-family: "Poppins", sans-serif;
      font-family: "Montserrat", sans-serif;
      font-size: 24px;
      font-weight: 700;
      line-height: 150%;
      text-transform: uppercase;
      display: flex;
      flex-direction: column;
      justify-content: center;

      span {
        text {
          // color: #fb4500;
          color: #1d2d5a;
          // font-family: "Poppins", sans-serif;
          font-family: "Montserrat", sans-serif;
          font-size: 24px;
          font-weight: 700;
          line-height: 150%;
          text-transform: uppercase;
        }
      }
    }
  }

  .subHeading {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    span {
      width: 85%;
      color: #303030;
      // font-family: "Poppins", sans-serif;
      font-family: "Montserrat", sans-serif;
      font-size: 1.8vh;
      font-weight: 400;
      line-height: 3vh;
    }
  }

  .Header {
    .Heading {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      .rightText {
        // color: #303030;
        padding-top: 2vh;
        color: #1d2d5a;
        // font-family: "Poppins", sans-serif;
        font-family: "Montserrat", sans-serif;
        font-size: 24px;
        font-weight: 700;
        line-height: 150%;
        text-transform: uppercase;
        display: flex;
        flex-direction: column;
        justify-content: center;

        span {
          text {
            color: #fb4500;
            // font-family: "Poppins", sans-serif;
            font-family: "Montserrat", sans-serif;
            font-size: 24px;
            font-weight: 700;
            line-height: 150%;
            text-transform: uppercase;
          }
        }
      }
    }

    .subHeading {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin-bottom: 12px;

      span {
        width: 100%;
        color: #303030;
        // font-family: "Poppins", sans-serif;
        font-family: "Montserrat", sans-serif;
        font-size: 12px;
        line-height: 24px;
        font-weight: 500;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 94%;
    gap: 24px;
    padding-bottom: 6vh;

    @media (min-width:1064px) {
      flex-direction: row;
    }

    .content1 {
      // border-radius: 12px;
      // background: rgba(255, 178, 178, 0.12);
      border-radius: 12px;
      border: 1px solid #ffce6e;
      background: rgba(255, 227, 145, 0.25);
      height: 371px;
      width: 100%;
      padding-bottom: 12px;

      .card {
        display: flex;
        align-items: baseline;
        padding: 24px;
        flex-direction: column;
        text-align: left;
        gap: 32px;

        .headerMain {
          display: flex;
          flex-direction: column;
          align-items: baseline;

          .Heading {
            // color: rgba(251, 69, 0, 0.81);
            color: #00468b;
            // font-family: "Poppins", sans-serif;
            font-family: "Montserrat", sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            /* 24px */
            text-transform: uppercase;
          }

          .subHeading {
            color: #303030;
            // font-family: "Poppins", sans-serif;
            font-family: "Montserrat", sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            /* 16.8px */
            text-transform: uppercase;
            padding-top: 4px;
          }
        }

        .headerSec {
          display: flex;
          flex-direction: column;
          align-items: baseline;

          .Heading {
            color: #303030;
            // font-family: "Poppins", sans-serif;
            font-family: "Montserrat", sans-serif;
            font-size: 32px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%;
            /* 38.4px */
            text-transform: uppercase;
          }

          .subHeading {
            color: rgba(48, 48, 48, 0.72);
            // font-family: "Poppins", sans-serif;
            font-family: "Montserrat", sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%;
            /* 24px */
            text-transform: uppercase;
          }
        }

        .headerThr {
          display: flex;
          flex-direction: column;
          align-items: baseline;
          gap: 12px;
          padding-top: 8px;

          .Heading {
            display: flex;
            justify-content: space-evenly;
            gap: 4px;

            text {
              color: #303030;

              // font-family: "Poppins", sans-serif;
              font-family: "Montserrat", sans-serif;
              font-size: 24px;
              font-style: normal;
              font-weight: 600;
              line-height: 120%;
              /* 28.8px */
              text-transform: uppercase;
            }
          }

          .subHeading {
            display: flex;
            justify-content: space-evenly;
            gap: 4px;

            text {
              color: #303030;
              // font-family: "Poppins", sans-serif;
              font-family: "Montserrat", sans-serif;
              font-size: 24px;
              font-style: normal;
              font-weight: 600;
              line-height: 120%;
              /* 28.8px */
              text-transform: uppercase;
            }
          }
        }
      }
    }

    .content2 {
      // border-radius: 12px;
      // background: rgba(143, 253, 141, 0.12);
      border-radius: 12px;
      border: 1px solid #ffce6e;
      background: rgba(255, 227, 145, 0.25);
      height: 371px;
      width: 100%;
      padding-bottom: 12px;

      .card {
        display: flex;
        align-items: baseline;
        padding: 24px;
        flex-direction: column;
        text-align: left;
        gap: 32px;

        .headerMain {
          display: flex;
          flex-direction: column;
          align-items: baseline;

          .Heading {
            // color: rgba(251, 69, 0, 0.81);
            color: #00468b;
            // font-family: "Poppins", sans-serif;
            font-family: "Montserrat", sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            /* 24px */
            text-transform: uppercase;
          }

          .subHeading {
            color: #303030;
            // font-family: "Poppins", sans-serif;
            font-family: "Montserrat", sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            /* 16.8px */
            text-transform: uppercase;
            padding-top: 4px;
          }
        }

        .headerSec {
          display: flex;
          flex-direction: column;
          align-items: baseline;

          .Heading {
            color: #303030;
            // font-family: "Poppins", sans-serif;
            font-family: "Montserrat", sans-serif;
            font-size: 32px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%;
            /* 38.4px */
            text-transform: uppercase;
          }

          .subHeading {
            color: rgba(48, 48, 48, 0.72);
            // font-family: "Poppins", sans-serif;
            font-family: "Montserrat", sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%;
            /* 24px */
            text-transform: uppercase;
          }
        }

        .headerThr {
          display: flex;
          flex-direction: column;
          align-items: baseline;
          gap: 12px;
          padding-top: 8px;

          .Heading {
            display: flex;
            justify-content: space-evenly;
            gap: 4px;

            text {
              color: #303030;

              // font-family: "Poppins", sans-serif;
              font-family: "Montserrat", sans-serif;
              font-size: 24px;
              font-style: normal;
              font-weight: 600;
              line-height: 120%;
              /* 28.8px */
              text-transform: uppercase;
            }
          }

          .subHeading {
            display: flex;
            justify-content: space-evenly;
            gap: 4px;

            text {
              color: #303030;

              // font-family: "Poppins", sans-serif;
              font-family: "Montserrat", sans-serif;
              font-size: 24px;
              font-style: normal;
              font-weight: 600;
              line-height: 120%;
              /* 28.8px */
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }

  .buttonDownload {
    display: flex;
    justify-content: center;

    .button {
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      // background: linear-gradient(180deg, #fb4500 0%, #ff8d61 100%);
      // border-radius: 60px;

      border-radius: 60px;
      background: #e6ac3a;
      height: 5.5vh;
      width: 85%;
      cursor: pointer;

      span {
        color: #fff;
        // font-family: 'Poppins', sans-serif;
        font-family: "Montserrat", sans-serif;
        // font-size: 1.8vh;
        font-size: 16px;
        font-weight: 600;
        line-height: 3vh;
        font-style: normal;
        // line-height: normal;
        letter-spacing: 2.16px;
        text-transform: uppercase;
      }
    }
  }
}