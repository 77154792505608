// font-family: 'Montserrat', sans-serif;
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.page5 {
  //   display: flex;
  //   flex-direction: column;
  //   gap: 24px;
  //   justify-content: center;
  //   align-items: center;
  background: white;
  padding-top: 1vh;

  .whyChooseAngHead {
    color: #1d2d5a;
    text-align: center;
    // font-family: Montserrat;
    font-family: "Montserrat", sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.28px;
    text-transform: uppercase;
  }

  .whyChooseAngPara {
    color: #303030;
    text-align: center;
    // font-family: Montserrat;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    width: 85%;
    margin: auto;
  }

  .firstHr {
    background: #d4d4d4;
    width: 342px;
    height: 0px;
    margin-top: 2vh;
    opacity: 0.5;
  }

  .Head {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .rightText {
      color: #303030;
      //   font-family: "Poppins", sans-serif;
      font-family: "Montserrat", sans-serif;
      font-size: 20px;
      font-weight: 700;
      line-height: 150%;
      text-transform: uppercase;
      display: flex;
      flex-direction: column;
      justify-content: center;

      span {
        color: #fb4500;
        text-align: center;
        // font-family: "Poppins", sans-serif;
        font-family: "Montserrat", sans-serif;
        font-size: 20px;
        font-weight: 700;
        line-height: 150%;
        text-transform: uppercase;
        justify-content: center;
        display: flex;
        flex-direction: column;
      }
    }
  }

  .Container {
    display: flex;
    flex-direction: column;
    // width: 94%;
    background: white;
    // border-radius: 12px;
    // box-shadow: 4px 4px 10px 4px rgba(185, 182, 182, 0.25);
    gap: 12px;
    margin-bottom: 24px;

    .firstContainer {
      .subHeadSecond {
        color: #303030;
        text-align: center;
        font-family: "Montserrat", sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 118.243%;
        text-transform: uppercase;
        text-align: center;
        width: 63%;
        margin: auto;
        padding-top: 2vh;
      }

      .upperListContent {
        .upperListContentSection {
          display: grid;
          grid-template-columns: 6% 1fr;
          // align-items: center;
          align-items: baseline;
          padding-left: 11vw;

          svg {
            width: 20px;
            height: 12px
          }

          p {
            // color: #303030;
            // font-family: "Montserrat", sans-serif;
            // font-size: 14px;
            // font-style: normal;
            // font-weight: 500;
            // line-height: 130%;

            width: 85%;
            color: #303030;
            font-family: "Montserrat", sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%;
            opacity: 0.8;
          }
        }
      }

      .firstContainerImage {
        width: 70%;
        margin: auto;
        padding-top: 2vh;

        img {
          width: 100%;
          height: 40vh;
          object-fit: cover;
        }
      }
    }

    .secondContainer {
      .headSecond {
        color: #303030;
        text-align: center;
        font-family: "Montserrat", sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 118.243%;
        text-transform: uppercase;
        width: 63%;
        margin: auto;
        padding-top: 2vh;
      }

      .middleListContent {
        .middleListContentSection {
          display: grid;
          grid-template-columns: 6% 1fr;
          // align-items: center;
          align-items: baseline;
          padding-left: 11vw;

          svg {
            width: 20px;
            height: 12px
          }

          p {
            // color: #303030;
            // font-family: "Montserrat", sans-serif;
            // font-size: 14px;
            // font-style: normal;
            // font-weight: 500;
            // line-height: 130%;

            width: 85%;
            color: #303030;
            font-family: "Montserrat", sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%;
            opacity: 0.8;
          }
        }
      }

      .secondContainerImage {
        width: 70%;
        margin: auto;
        padding-top: 2vh;

        img {
          width: 100%;
          height: 40vh;
          object-fit: cover;
        }
      }

      .middleSectionHr {
        background: #d4d4d4;
        width: 342px;
        height: 0px;
        margin-top: 2vh;
        opacity: 0.5;
      }
    }

    .thirdContainer {
      .thirdContainerHead {
        color: #303030;
        text-align: center;
        font-family: "Montserrat", sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        text-transform: uppercase;
        width: 63%;
        margin: auto;
        // padding-top: 2vh;
      }

      .thirdListContent {
        .thirdListContentSection {
          display: grid;
          grid-template-columns: 6% 1fr;
          // align-items: center;
          align-items: baseline;
          padding-left: 11vw;

          svg {
            width: 20px;
            height: 12px
          }

          p {
            // color: #303030;
            // font-family: "Montserrat", sans-serif;
            // font-size: 14px;
            // font-style: normal;
            // font-weight: 500;
            // line-height: 130%;

            width: 85%;
            color: #303030;
            font-family: "Montserrat", sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%;
            opacity: 0.8;
          }
        }
      }

      .thirdHr {
        background: #d4d4d4;
        width: 342px;
        height: 0px;
        margin-top: 2vh;
        opacity: 0.5;
      }
    }

    .containerList {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: left;
      padding: 24px 8px;
      gap: 24px;

      .list {
        display: flex;

        text {
          width: 20%;
          color: rgba(48, 48, 48, 0.44);
          //   font-family: "Poppins", sans-serif;
          font-family: "Montserrat", sans-serif;
          font-size: 36px;
          font-style: normal;
          font-weight: 700;
          line-height: 130%;
        }

        span {
          width: 80%;
          color: rgba(48, 48, 48, 0.98);
          //   font-family: "Poppins", sans-serif;
          font-family: "Montserrat", sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 130%;
        }
      }
    }

    .listImg {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 300px;

      img {
        width: 100%;
        height: 40vh;
        object-fit: cover;
      }
    }

    .lastSection {
      display: flex;
      flex-direction: column;
      //   gap: 12px;

      .Heads {
        .rightText {
          span {
            color: #303030;
            // font-family: "Poppins", sans-serif;
            font-family: "Montserrat", sans-serif;
            font-size: 20px;
            font-weight: 700;
            line-height: 150%;
            text-transform: uppercase;
            display: flex;
            justify-content: center;
          }

          // text {
          //     color: #FB4500;
          //     padding-left: 2px;
          //     text-align: center;
          //     font-family: 'Poppins', sans-serif;
          //     font-size: 20px;
          //     font-weight: 700;
          //     line-height: 150%;
          //     text-transform: uppercase;
          //     justify-content: center;
          //     display: flex;

          // }

          .headBelowSection {
            .belowFirstSection {
              display: grid;
              grid-template-columns: 6% 1fr;
              // align-items: center;
              align-items: baseline;
              padding-left: 11vw;

              svg {
                width: 20px;
                height: 12px
              }

              p {
                width: 85%;
                color: #303030;
                font-family: "Montserrat", sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 130%;
                opacity: 0.8;
              }
            }
          }
        }
      }

      .listImg {
        width: 100%;
        height: 250px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .enquiryButtonDiv {
        display: flex;
        align-items: center;
        justify-content: center;

        .enquiryButton {
          border-radius: 60px;
          background: #00468b;
          font-family: "Montserrat", sans-serif;
          color: #fff;
          font-size: 18px;
          padding: 15px 32px;
          text-align: center;
          text-decoration: none;
          display: inline-block;
          font-size: 16px;
          margin: 4px 2px;
          // margin-bottom: 28px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 2.16px;
        }
      }
    }
  }
}