.DubaiOfferingOurOfferingMobile {

  @media (min-width:800px) {
     display: none;
  }
  // padding-left: 5vw;
  .DubaiOfferingOurOfferingMobileFlexSection {
    padding-top: 3vh;
    // display: flex;
    // gap: 5vw;
    // width: 100%;
    // overflow: hidden;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    cursor: grab;
    scroll-behavior: smooth;
    scrollbar-width: none;
    // padding-bottom: 5vh;
    // align-items: center;
    // justify-content: center;

    // margin-left: 5vw;

    margin-left: 5vw;
    margin-right: 5vw;

    .adjustInCenter {
      display: flex;
      gap: 5vw;
      width: 100%;
      // justify-content: center;
      .DubaiOfferingOurOfferingMobileFirstDiv {
        flex-shrink: 0;
        // flex: 0 0 100%;
        width: 90%;
        //   height: 68vh;
        // height: 81vh;
        scroll-snap-align: flex-start;
        user-select: none;
        // padding-left: 5vw;

        .DubaiOfferingOurOfferingMobileFirstDivImage {
          img {
            width: 80vw;
            height: 38vh;
            object-fit: cover;
            border-radius: 20px;
          }
        }

        .DubaiOfferingOurOfferingMobileUpperSection {
          transform: translateY(-100px);
          padding: 5vw;

          .DubaiOfferingOurOfferingMobileUpperSectionBorderRadius {
            border-radius: 5.744px;
            background: #fff;
            box-shadow: 0px 3.10409px 18.62455px 0px rgba(0, 0, 0, 0.1);
            padding: 15px;

            .DubaiOfferingOurOfferingMobileUpperSectionHead {
              color: #212529;
              font-family: "Josefin Sans", sans-serif;
              // font-size: 32px;
              font-size: 10vw;
              font-style: normal;
              font-weight: 600;
              text-transform: capitalize;
              margin: 0px;
            }

            .DubaiOfferingOurOfferingMobileUpperSectionPara {
              color: #000000;
              font-family: "Figtree", sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 200;
              line-height: 130%;
              text-transform: capitalize;
            }

            .DubaiOfferingOurOfferingMobileListFlex {
              flex-direction: column;
              display: flex;
              gap: 10px;

              .DubaiOfferingOurOfferingMobileListFlexFirst {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;

                .DubaiOfferingOurOfferingMobileListFlexPara {
                  color: #000;
                  font-family: "GothamLight";
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 550;
                  line-height: 150%;
                  text-transform: capitalize;
                  margin: 0px;
                  list-style-type: none;
                  display: flex;
                  gap: 6px;
                }
              }
            }
          }
        }
      }
    }
  }

  .DubaiOfferingOurOfferingMobileFlexSection::-webkit-scrollbar {
    display: none;
  }

  .DubaiOfferingOurOfferingMobileMultipleDot {
    display: flex;
    align-items: center;
    justify-content: center;
    // padding-top: 30vh;

    // .dot {
    //     cursor: pointer;
    //     height: 7px;
    //     width: 7px;
    //     margin: 0 2px;
    //     background-color: #bbb;
    //     border-radius: 50%;
    //     display: inline-block;
    //     transition: background-color 0.6s ease;

    //     // .active,
    //     &.active {
    //         background-color: #717171;
    //     }
    // }

    // .coloredDot {
    //     background-color: #000 !important;
    // }

    .dot {
      cursor: pointer;
      height: 7px;
      width: 7px;
      margin: 0 2px;
      background-color: #bbb;
      border-radius: 50%;
      display: inline-block;
      transition: background-color 0.6s ease;
    }

    .dot.active,
    .dot.coloredDot {
      background-color: #717171 !important;
    }
  }

  .threedots {
    padding: 16px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .carousel-dots {
      display: flex;
      gap: 4px;
      justify-content: center;
      align-items: center;
      width: 100%;
      overflow: hidden;
    }

    .press-carousel-item {
      flex: 0 0 auto;
      width: 9px;
      height: 9px;
      border-radius: 50%;
      display: flex;
      background-color: rgba(0, 0, 0, 0.15);
    }

    .press-carousel-item.active {
      background-color: #000;
      color: #fff;
    }
  }
}
